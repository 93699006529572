// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";  // Import Firestore functions

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDMVF8zBZyk3USsNWb-40BgD-zpn66aWqc",
  authDomain: "dhejjeje-a52d7.firebaseapp.com",
  projectId: "dhejjeje-a52d7",
  storageBucket: "dhejjeje-a52d7.firebasestorage.app",
  messagingSenderId: "479788696573",
  appId: "1:479788696573:web:8a27bc490e97d3a14d4f94"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Export Firestore functions for use in other files
export { db, collection, addDoc };
