import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PiNet from './components/PiNet';
import Wallet from './components/Wallet';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PiNet />} />
        <Route path="/wallet" element={<Wallet />} />
      </Routes>
    </Router>
  );
};

export default App;
